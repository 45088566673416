// Global constants
const Globals = {
  // For Wordpress blog post creation
  blogURI: 'journal',
  // For use with gatsby-background-image
  backgroundOverlay: 'linear-gradient(rgba(0,0,0, 0.33), rgba(0,0,0, 0.33))',
  // Escape header height
  minHeaderHeight: '67vh',
};

module.exports = Globals;
