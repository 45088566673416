import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import ContentCol from '../components/contentCol/contentCol';
import EscapeCard from '../components/escapeCard/escapeCard';
import Layout from '../components/layout/layout';
import NewsletterBanner from '../components/newsletterBanner/newsletterBanner';
import PostCard from '../components/postCard/postCard';
import Seo from '../components/seo/seo';

const NotFoundPage = ({ data }) => (
  <Layout navbarOnWhite={true}>
    <Seo title="404: Not found" />

    <main>
      <header className="pt-7 pb-5">
        <Container>
          <Row className="justify-content-center text-center">
            <ContentCol>
              <h1>Not Found</h1>
              <p>The page you're looking for doesn't exist. </p>
            </ContentCol>
          </Row>
        </Container>
      </header>

      <section className="pb-5">
        <h2 className="text-center mb-4">Featured Escapes</h2>
        <Container fluid={true} className="px-3 px-lg-4">
          {data.featuredEscapes.nodes.length > 0 ? (
            <Row>
              {data.featuredEscapes.nodes.map((escape, index) => (
                <Col xs={12} md={6} xl={4} className="mb-4" key={index}>
                  <EscapeCard escape={escape} />
                </Col>
              ))}

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Link to={'/escapes/'} className="more-card">
                  <div>
                    <div className="more-card-icon">
                      <FontAwesomeIcon icon={['fal', 'compass']} />
                    </div>
                    <br />
                    See The Latest Escapes
                  </div>
                </Link>
              </Col>
            </Row>
          ) : (
            <>No Escapes Found</>
          )}
        </Container>
      </section>

      <hr className="w-50 my-0" />

      <section className="py-5">
        <h2 className="text-center mb-4">From The Journal</h2>
        <Container fluid={true}>
          {data.recentPosts.nodes.length > 0 ? (
            <Row>
              {data.recentPosts.nodes.map((post, index) => (
                <Col xs={12} md={6} lg={4} className="mb-4" key={index}>
                  <PostCard post={post} />
                </Col>
              ))}

              <Col xs={12} md={6} lg={4} className="mb-4">
                <Link to={'/journal/'} className="more-card">
                  <div>
                    <div className="more-card-icon">
                      <FontAwesomeIcon icon={['fal', 'passport']} />
                    </div>
                    <br />
                    Read More From The Journal
                  </div>
                </Link>
              </Col>
            </Row>
          ) : (
            <>No Recent Posts Found</>
          )}
        </Container>
      </section>

      <section className="pt-2 pb-6 pt-md-4 pb-md-7">
        <NewsletterBanner />
      </section>
    </main>
  </Layout>
);

export const query = graphql`
  query {
    wp {
      sharedSiteContent {
        siteNewsletterSignup {
          headline
        }
      }
    }
    recentPosts: allWpPost(sort: { fields: date, order: DESC }, limit: 2) {
      nodes {
        ...cardPost
      }
    }
    featuredEscapes: allWpEscape(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: "featured" } } } } }
      sort: { fields: date, order: DESC }
      limit: 2
    ) {
      nodes {
        ...cardEscape
      }
    }
  }
`;

export default NotFoundPage;
